/** @jsx jsx */
import { useTranslate, utils } from '@chordcommerce/gatsby-theme-autonomy'
import PropTypes from 'prop-types'
import { Box, Flex, Heading, jsx } from 'theme-ui'
import LineItem from '~/components/Cart/LineItem'

const LineItems = ({
  items = [],
  orderConfirmed = false,
  amountNeededForFreeShipping,
  eligibleForFreeShipping = false
}) => {
  const translate = useTranslate()
  const { toUsdCurrency } = utils

  items = items.filter(item => item && !item.variant.sample)

  const itemCount = items.reduce((acc, item) => {
    return acc + item.quantity
  }, 0)

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Heading
        as="h3"
        variant="h3"
        sx={{
          marginBottom: ['0.5rem', null, '2.5rem'],
          textAlign: ['center', null, 'left']
        }}
      >
        {translate('cart.your_items')} ({itemCount})
      </Heading>

      {!orderConfirmed && (
        <Flex
          sx={{
            alignItems: 'center',
            height: '3rem',
            justifyContent: 'space-around'
          }}
        >
          {eligibleForFreeShipping
            ? translate('cart.shipping_banner_free')
            : translate('cart.shipping_banner_not_free', {
                amount: toUsdCurrency(amountNeededForFreeShipping)
              })}
        </Flex>
      )}

      {items.map((li, index) => (
        <Box
          key={index}
          sx={{
            borderBottom: '1px solid',
            borderBottomColor: '#E3E4E3'
          }}
        >
          <LineItem
            id={li.id}
            sku={li.variant && li.variant.sku}
            slug={li.variant && li.variant.slug}
            name={li.variant && li.variant.name}
            options={li.variant && li.variant.optionsText}
            quantity={li.quantity}
            displayAmount={li.displayAmount}
            singleDisplayAmount={li.singleDisplayAmount}
            orderConfirmed={orderConfirmed}
            subscriptions={li.subscriptionLineItems}
          />
        </Box>
      ))}
    </Flex>
  )
}

LineItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      src: PropTypes.string,
      quantity: PropTypes.number,
      displayAmount: PropTypes.string,
      singleDisplayAmount: PropTypes.string
    })
  ),
  orderConfirmed: PropTypes.bool,
  amountNeededForFreeShipping: PropTypes.number,
  eligibleForFreeShipping: PropTypes.bool
}

export default LineItems
