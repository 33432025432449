/** @jsx jsx */
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from 'prop-types'
import { Box, Flex, jsx, Text } from 'theme-ui'
import QuantitySelector from '~/components/Cart/QuantitySelector'
import SubscriptionDetails from '~/components/Cart/Subscription/Details'
import SubscriptionRemoveButton from '~/components/Cart/Subscription/RemoveButton'
import useProductMainImage from '~/hooks/components/use-product-main-image'

const LineItem = ({
  id,
  sku,
  slug,
  name,
  options,
  quantity,
  displayAmount,
  singleDisplayAmount,
  orderConfirmed = false,
  subscriptions = []
}) => {
  const { trackClickProduct } = useAnalytics()
  const path = sku.includes('|') ? 'bundles' : 'products'
  const url = `/${path}/${slug}/`

  const { image } = useProductMainImage({ sku, slug })
  const gImage = getImage(image)

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        marginBottom: ['12px', '20px'],
        marginTop: ['12px', '20px'],
        justifyContent: 'space-between'
      }}
    >
      <Flex
        sx={{
          flexDirection: ['row']
        }}
      >
        {gImage && (
          <Box
            sx={{
              minWidth: ['96px', '144px']
            }}
          >
            <Link to={url} onClick={() => trackClickProduct(slug)}>
              <GatsbyImage image={gImage} alt={image.title} />
            </Link>
          </Box>
        )}
        <Flex
          sx={{
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
            marginLeft: '1rem'
          }}
        >
          <Text
            sx={{
              marginBottom: '0.5rem',
              variant: 'text.h4'
            }}
          >
            {name}
          </Text>
          <Text
            sx={{
              marginBottom: '0.5rem',
              variant: 'text.h4'
            }}
          >
            {options}
          </Text>

          {subscriptions.length === 0 && (
            <Text variant="body">{singleDisplayAmount}</Text>
          )}

          {subscriptions.length > 0 && (
            <Box sx={{ marginTop: '2rem' }}>
              <Text
                sx={{
                  variant: 'text.h4'
                }}
              >
                Subscription Summary:
              </Text>
              {subscriptions.map((subscription, index) => (
                <SubscriptionDetails
                  key={index}
                  subscription={subscription}
                  singleDisplayAmount={singleDisplayAmount}
                  displayAmount={displayAmount}
                />
              ))}
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex
        sx={{
          flexDirection: ['row', 'column'],
          alignItems: ['center', 'flex-end'],
          justifyContent: 'space-between',
          height: ['48px', '160px'],
          marginTop: ['12px', '0']
        }}
      >
        {orderConfirmed && <Text>{quantity}</Text>}

        <Text variant="body">{displayAmount}</Text>

        {!orderConfirmed && subscriptions.length === 0 && (
          <QuantitySelector
            quantity={quantity}
            lineItemId={id}
            sx={{
              marginRight: ['0', null, '4px'],
              width: ['96px', '128px']
            }}
          />
        )}

        {!orderConfirmed && subscriptions && subscriptions.length > 0 && (
          <SubscriptionRemoveButton lineItemId={id} />
        )}
      </Flex>
    </Flex>
  )
}

LineItem.propTypes = {
  id: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string,
  quantity: PropTypes.number,
  displayAmount: PropTypes.string,
  singleDisplayAmount: PropTypes.string,
  orderConfirmed: PropTypes.bool
}

export default LineItem
