/** @jsx jsx */

import { useEffect, useState } from 'react'
import { Box, Container, Flex, Heading, jsx, Spinner } from 'theme-ui'
import {
  useCart,
  useTranslate,
  useUser
} from '@chordcommerce/gatsby-theme-autonomy'
import OrderConfirmationReferralPrompt from '~/components/Order/Confirmation/ReferralPrompt'
import OrderConfirmationSummary from '~/components/Order/Confirmation/Summary'
import OrderConfirmationCart from '~/components/Order/Confirmation/Cart'
import OrderConfirmationDetails from '~/components/Order/Confirmation/Details'
import OrderConfirmationContact from '~/components/Order/Confirmation/Contact'
import UserService from '~/api/services'
import CONFIG from '~/config'

const windowGlobal = typeof window !== 'undefined' && window
const AMBASSADOR = CONFIG.ROLES.ambassador
const CUSTOMER = CONFIG.ROLES.customer

const OrderConfirmationPage = () => {
  const { user, loadUser } = useUser()
  const { finalizeCheckout } = useCart()
  //const [cart, setCart] = useState(null)
  const { cart, loadCart } = useCart()
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(async() => {
    if(cart?.data?.number)
    {
      let orderNumber = cart?.data?.number
      console.log({orderNumber})
      //finalizeCheckout().then(cart => { setCart(cart) })
      try{
        await finalizeCheckout({ orderNumber })  
        setIsLoading(false)
      }
      catch(err){
        console.log(err)
      }
    }
  }, [finalizeCheckout, loadUser])

  const referralPurl = user.referralIdentifier && user.referralIdentifier.purl

  //create user using the serverless API

  useEffect(() => {
    async function create(userId, name, email) {
      let role

      //checkout successful, check becomeAmbassador setting(the user sign up and bought a product)
      const becomeAmbassador = localStorage.getItem('becomeAmbassador')

      if(windowGlobal)
      {
        if(becomeAmbassador)
        {
          role = AMBASSADOR
        }
        else {
          role = CUSTOMER
        }
      }

      try{
        await UserService.createUser(userId, name, email, role)
      }
      catch(error)
      {
        console.error("Could not create user")
      }
    }

  async function updateAffiliateId(email)
    {
      //checks the affiliate
      let aff = windowGlobal && localStorage.getItem('affiliate')
      if(aff)
      {
        try{
          //update affiliate with email of the person who signs up under the affiliate
          let result = await UserService.updateAffiliateAffiliated(email, aff)
          if(result && result.data && result.data.success)
          {
            console.log('Updated affiliate')
          }
        }
        catch(error)
        {
          console.error("Could not create user")
        }
      }
      else {
          //if there is no affiliate (coach) selected, check in the DB if there is a previous association
          UserService.matchEmailWithAffiliateCode(email).then((content) => {windowGlobal && localStorage.setItem('affiliate', content.data.affId)}).catch(() => {
          console.error('error fetching match');

        })
      } 
    }

    if(user && user.data && user.data.id)
    {
      create(user.data.id, user.data.name, user.data.email)
      updateAffiliateId(user.data.email)
    }
  }, [user])

  if (error) return <ConfirmationError error={error} />
  if (isLoading || !cart) return <ConfirmationLoading />

  return (
    <ConfirmationContainer cart={cart}>
      <Container>
        <Flex
          sx={{
            flexDirection: ['column', null, 'row']
          }}
        >
          <Flex
            sx={{
              width: ['100%', null, '60%'],
              flexDirection: 'column',
              paddingRight: [null, null, '0.75rem'],
              textAlign: ['center', null, 'left']
            }}
          >
            <OrderConfirmationDetails cart={cart} />

            {referralPurl && (
              <OrderConfirmationReferralPrompt purl={referralPurl} />
            )}

            <OrderConfirmationContact />
          </Flex>

          <Flex
            sx={{
              width: ['100%', null, '40%'],
              flexDirection: 'column',
              paddingLeft: [null, null, '0.75rem']
            }}
          >
            <OrderConfirmationSummary cart={cart} />
            <OrderConfirmationCart cart={cart} />
          </Flex>
        </Flex>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationLoading = () => {
  return (
    <ConfirmationContainer loaded={false}>
      <Container>
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            backgroundColor: 'white',
            padding: ['1.25rem', '12rem 1.25rem'],
            marginRight: [null, '1rem'],
            marginBottom: ['1rem', null]
          }}
        >
          <Spinner size="50" />
        </Box>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationError = () => {
  const translate = useTranslate()

  return (
    <ConfirmationContainer loaded={false}>
      <Container>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'white',
            padding: '1rem',
            minHeight: '50vh'
          }}
        >
          <Heading variant="h3" color="errorDark">
            {translate('error.api.default')}
          </Heading>
        </Box>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationContainer = ({ children, cart = {}, loaded = true }) => {
  const translate = useTranslate()
  const firstName = cart.billAddress && cart.billAddress.firstname

  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          padding: ['24px 0', null, '48px 0']
        }}
      >
        {loaded && (
          <Heading
            as="h2"
            variant="h2"
            sx={{
              marginBottom: ['24px', null, '64px'],
              textAlign: 'center'
            }}
          >
            {`${translate('confirmation.title')}, ${firstName}.`}
          </Heading>
        )}
        {children}
      </Flex>
    </Container>
  )
}

export default OrderConfirmationPage
