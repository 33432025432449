import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import { Flex, Heading, Text, Button } from 'theme-ui'
import CheckoutButton from '~/components/Cart/CheckoutButton'
import PromoCode from '~/components/Cart/PromoCode'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
//import Autocomplete from 'react-autocomplete'
import UserService from '~/api/services'
import Modal from 'react-modal';

const windowGlobal = typeof window !== 'undefined' && window

const MIN_QUERY_CHARACTERS = 3 //ambassadors autocomplete shows ids after 3 characters

const RowWrapper = ({ children }) => (
  <Flex
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      my: '.75rem'
    }}
  >
    {children}
  </Flex>
)

const CartSummary = ({
  displayItemTotal,
  displayTaxTotal,
  displayShipTotal,
  displayTotal,
  promotions,
  orderConfirmed = false,
  eligibleForFreeShipping = false,
  showNavBar,
  setShowNavBar,
  showPromo,
  setShowPromo,
  setMessage,
  setShowCheckout,
  showCheckout
}) => {
  const translate = useTranslate()
  const promoCode = promotions ? promotions.find(p => p.code) : null
  const [value, setValue] = useState("")
  const [affIds, setAffIds] = useState([])
  const [formattedIds, setFormattedIds] = useState([])
  const [agreed, setAgreed] = useState(false)
  const [modalIsOpen, setModalOpen] = useState(false)
  const [hasKit, setHasKit] = useState(null)
  const [closeDropdown, setCloseDropdown] = useState(false)

  const closeModal = () => {
    setModalOpen(false)
  }

  const setCookie = (affId) => { 
    windowGlobal && localStorage.setItem('affiliateCode', affId)
  }

  const formatData = (arr) => {
    let newArr = []
    if(arr.data)
    {
      arr.data && arr.data.forEach(item => {
        if(item)
        {
          let obj = {}
          obj['id'] = item.affId
          obj['label'] = item.affId
          newArr.push(obj)
        }
      })
      setFormattedIds(newArr)
    }
    
  }

  useEffect(() => {
    if(windowGlobal && localStorage.getItem('ContainsKit'))
      setHasKit(JSON.parse(localStorage.getItem('ContainsKit')))
    else
      setHasKit(null)

  }, [displayItemTotal])

  useEffect(() => {
    function fetchData() {
      UserService.getAllAmbassadorsIds().then((content) => setAffIds(content.data)).catch(() => {
        console.error('error fetching ids');
        setMessage('Error fetching ids')
      })
    }

    fetchData()

  }, [])

  useEffect(() => {
    if(affIds)
    {
      formatData(affIds)
    }
  }, [affIds])

  const customStyles = {
    content: {
      width: '100vw',
      zIndex: 9999,
      inset: '0px'
    },
  };

  return (
  <>
    <Flex
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Heading
        as="h3"
        variant="h3"
        sx={{
          marginBottom: ['0.5rem', null, '2rem'],
          textAlign: ['center', null, 'left']
        }}
      >
        {translate('cart.order_summary')}
      </Heading>

      {!orderConfirmed && <PromoCode promotion={promoCode} />}

      <div className="d-flex flex-row">
        <div>Coach:</div> 
        <div className="ms-3">
          {/*<Autocomplete
            shouldItemRender={(item, value) => item?.label?.toLowerCase().indexOf(value.toLowerCase()) > -1}
            getItemValue={(item) => item.label}
            items={formattedIds}
            renderItem={(item, isHighlighted) =>
              <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
              </div>
            }
            value={value}
            onChange={(e) => {setValue(e.target.value); setCookie(e.target.value)}}
            onSelect={(val) => {setValue(val); setCloseDropdown(true); setCookie(val)}}
            open={(value.length >= MIN_QUERY_CHARACTERS && !closeDropdown)}
          />*/}
        </div>
      </div>

      {displayItemTotal && (
        <RowWrapper>
          <Text>{translate('cart.subtotal')}</Text>
          <Text>{displayItemTotal}</Text>
        </RowWrapper>
      )}

      {promotions.map(({ id, label, displayAmount }) => {
        return (
          <RowWrapper key={id}>
            <Text>{label}</Text>
            <Text>{displayAmount}</Text>
          </RowWrapper>
        )
      })}

      {displayTaxTotal && (
        <RowWrapper>
          <Text>{translate('cart.tax')}</Text>
          <Text>{displayTaxTotal}</Text>
        </RowWrapper>
      )}

      {displayShipTotal && (
        <>
        <RowWrapper>
          <Text>
            {orderConfirmed || eligibleForFreeShipping
              ? translate('cart.shipping')
              : translate('cart.calculated_at_checkout')}
          </Text>
          <Text>
            {eligibleForFreeShipping
              ? translate('cart.free_shipping')
              : displayShipTotal}
          </Text>
        </RowWrapper>

        {/*<div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked/>
          <label class="form-check-label" for="flexRadioDefault1">
            Standard
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
          <label class="form-check-label" for="flexRadioDefault2">
            Express
          </label>
        </div>*/}
        </>
      )}

      {displayTotal && (
        <RowWrapper>
          {!orderConfirmed && <Text>{translate('cart.estimated_total')}</Text>}
          {orderConfirmed && <Text>{translate('cart.total')}</Text>}
          <Text>{displayTotal}</Text>
        </RowWrapper>
      )}

      {hasKit?.contains ? 
        !orderConfirmed && agreed && <CheckoutButton setMessage={setMessage} setShowCheckout={setShowCheckout} showCheckout={showCheckout}/> :
        !orderConfirmed && <CheckoutButton setMessage={setMessage} setShowCheckout={setShowCheckout} showCheckout={showCheckout}/>
      }

      { hasKit?.contains &&
        <Button sx={{ width: '100%', marginTop: '1rem' }} onClick={() => {setModalOpen(true); setShowNavBar(false); setShowPromo(false)}}>
          <Text>{translate('cart.terms')}</Text>
        </Button>
      }

       <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Terms Agreement"
      >
        <h2>Terms Agreement</h2>
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur. At lectus urna duis convallis. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Malesuada proin libero nunc consequat interdum varius sit amet. Tincidunt vitae semper quis lectus nulla. Auctor elit sed vulputate mi sit amet. In nibh mauris cursus mattis molestie a iaculis at. Nunc non blandit massa enim nec dui nunc mattis enim. Aenean euismod elementum nisi quis eleifend. Ipsum dolor sit amet consectetur adipiscing. Vitae suscipit tellus mauris a diam maecenas. Tempor nec feugiat nisl pretium fusce id velit. Non enim praesent elementum facilisis leo vel fringilla est. Id diam maecenas ultricies mi eget mauris pharetra. Sit amet venenatis urna cursus eget nunc scelerisque. Et odio pellentesque diam volutpat. Dictum varius duis at consectetur lorem donec massa.

          A diam maecenas sed enim ut sem viverra. Tincidunt augue interdum velit euismod. Nulla pellentesque dignissim enim sit amet venenatis urna. In iaculis nunc sed augue lacus viverra vitae congue. Tristique senectus et netus et malesuada fames. Facilisi nullam vehicula ipsum a arcu cursus. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Eget gravida cum sociis natoque penatibus et magnis dis parturient. Gravida arcu ac tortor dignissim convallis aenean et tortor at. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus. Fringilla est ullamcorper eget nulla facilisi etiam dignissim. Diam sit amet nisl suscipit adipiscing bibendum est ultricies integer. Pellentesque sit amet porttitor eget dolor morbi non arcu. Velit laoreet id donec ultrices tincidunt. Lobortis scelerisque fermentum dui faucibus in ornare quam.

          Pellentesque habitant morbi tristique senectus et netus et. Viverra justo nec ultrices dui sapien eget mi proin sed. Sollicitudin ac orci phasellus egestas tellus. Tincidunt augue interdum velit euismod in pellentesque massa. Ultrices mi tempus imperdiet nulla malesuada pellentesque elit. Est lorem ipsum dolor sit amet. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Ut etiam sit amet nisl. Amet aliquam id diam maecenas ultricies mi eget. Adipiscing tristique risus nec feugiat in fermentum posuere urna. Est ullamcorper eget nulla facilisi etiam dignissim. Velit ut tortor pretium viverra suspendisse potenti nullam. Ut ornare lectus sit amet est placerat in egestas erat. Elementum nisi quis eleifend quam adipiscing vitae proin. Aliquam sem et tortor consequat id porta nibh. Senectus et netus et malesuada fames ac turpis egestas sed. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum.

          Mattis pellentesque id nibh tortor id. In iaculis nunc sed augue lacus. In est ante in nibh mauris cursus mattis molestie. Sed faucibus turpis in eu mi bibendum neque egestas. Morbi tristique senectus et netus et malesuada fames ac turpis. Et tortor at risus viverra. Ultrices sagittis orci a scelerisque purus semper eget. Ipsum dolor sit amet consectetur adipiscing elit duis tristique sollicitudin. Non sodales neque sodales ut etiam sit amet nisl purus. Molestie ac feugiat sed lectus vestibulum mattis. Vel facilisis volutpat est velit. Mauris nunc congue nisi vitae. In metus vulputate eu scelerisque felis imperdiet proin. Tempus egestas sed sed risus pretium quam vulputate. Leo urna molestie at elementum eu facilisis sed odio. Suscipit tellus mauris a diam maecenas sed enim. Fames ac turpis egestas integer eget aliquet nibh praesent tristique. Risus ultricies tristique nulla aliquet enim tortor at auctor. Mattis rhoncus urna neque viverra justo nec ultrices dui. Auctor urna nunc id cursus metus aliquam eleifend.

          Amet luctus venenatis lectus magna fringilla urna porttitor rhoncus. Commodo nulla facilisi nullam vehicula. Arcu non sodales neque sodales ut etiam sit amet. Dolor sit amet consectetur adipiscing elit ut aliquam. Convallis tellus id interdum velit laoreet id donec. Et ligula ullamcorper malesuada proin libero nunc consequat interdum. Aenean et tortor at risus. Imperdiet dui accumsan sit amet nulla. Sed libero enim sed faucibus turpis in. Donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Ultrices sagittis orci a scelerisque. Congue quisque egestas diam in. Cursus metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Mollis aliquam ut porttitor leo a. Nibh ipsum consequat nisl vel pretium lectus quam id leo. Et sollicitudin ac orci phasellus egestas. Risus at ultrices mi tempus imperdiet nulla malesuada pellentesque elit. Libero nunc consequat interdum varius sit amet mattis. Id venenatis a condimentum vitae sapien pellentesque. Sapien et ligula ullamcorper malesuada proin libero.
        </div>
        <div className="list-group mt-3">
          <label className="list-group-item">
            <input className="form-check-input me-1" type="checkbox" value="Agree To Terms" aria-label="Agree To Terms"
            onClick={() => setAgreed(!agreed)}
            />
            {` `} Agree To Terms
          </label>
        </div>
        <Button sx={{ width: '100%', marginTop: '1rem' }} onClick={() => {setModalOpen(false); setShowNavBar(true); setShowPromo(true)}}>
          <Text>{translate('cart.close')}</Text>
        </Button>
      </Modal>

    </Flex>

    
  </>  
  )
}

CartSummary.propTypes = {
  displayItemTotal: PropTypes.string,
  displayTaxTotal: PropTypes.string,
  displayShipTotal: PropTypes.string,
  displayTotal: PropTypes.string,
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      displayAmount: PropTypes.string
    })
  ),
  orderConfirmed: PropTypes.bool
}

export default CartSummary
